// styles
@use 'styles/base/mixins';
@use 'styles/base/variables';

.Wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 3.75rem);
  max-height: 0;
  width: 100%;
  background-color: variables.$gb_white;
  overflow-y: auto;
  z-index: 2;
  transition:
    transform 0s variables.$transition-timing-ease-in-out,
    max-height variables.$transition-fast variables.$transition-timing-ease-in-out;
  display: flex;
  flex-direction: column;

  @include mixins.screen-sm {
    transform: translateX(23.0625rem);
    width: 23.0625rem;
    max-height: 100%;

    transition:
      transform variables.$transition-fast variables.$transition-timing-ease-in-out,
      max-height 0s variables.$transition-timing-ease-in-out;
  }
}

:global(.mobilenav-open) {
  .Wrapper {
    max-height: 100%;

    @include mixins.screen-sm {
      transform: translateX(0);
    }
  }
}

.Main {
  background: variables.$gb_white;

  @include mixins.screen-sm {
    flex: 1;
  }

  > div {
    opacity: 0;
    transform: translateX(-1.25rem);

    transition:
      transform variables.$transition-faster variables.$transition-timing-ease-in-out,
      opacity variables.$transition-faster variables.$transition-timing-ease-in-out,
      margin-bottom variables.$transition-fast variables.$transition-timing-ease-in-out;

    &.MenuShown {
      transform: translateX(0);
      opacity: 1;
    }

    &.MenuHidden {
      transform: translateX(-1.25rem);
      opacity: 0;
    }

    &.Lang {
      position: absolute;
      opacity: 1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      transform: translateX(0);
      pointer-events: none;

      > div {
        opacity: 0;
        transform: translateX(-1.25rem);
        height: 50%;

        transition:
          transform variables.$transition-faster variables.$transition-timing-ease-in-out,
          opacity variables.$transition-faster variables.$transition-timing-ease-in-out;

        &.LangShown {
          transform: translateX(0);
          opacity: 1;
          pointer-events: all;
        }

        &.LangHidden {
          transform: translateX(-1.25rem);
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }
}

:global(.language--shown) {
  .Meta {
    transform: translateY(100%);
  }

  .Wrapper {
    overflow: hidden;
  }
}

.Meta {
  transform: translateY(0);
  background: variables.$gb_grey_050;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 8rem;
  flex: 1;
  will-change: transform;
  transition: transform variables.$transition-faster variables.$transition-timing-ease-in-out
    variables.$transition-fast;

  :global(.isTouch) & {
    flex: 1;
  }

  @include mixins.screen-sm {
    flex: 0;
  }

  > div {
    opacity: 0;
    transform: translateX(-1.25rem);
    will-change: transform, opacity;

    transition:
      transform variables.$transition-faster variables.$transition-timing-ease-in-out,
      opacity variables.$transition-faster variables.$transition-timing-ease-in-out;

    &.MetaShown {
      transform: translateX(0);
      opacity: 1;

      .isGroup {
        a {
          display: -webkit-inline-box;
          display: -webkit-inline-flex;
          display: -ms-inline-flexbox;
          display: inline-flex;
          position: relative;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          height: 2.375rem;
          padding: 0 0.5rem;
          min-width: 3.75rem;
          color: var(--gb-brand-primary-light);
          border: 1px solid rgba(255, 255, 255, 0);
          font-weight: 500;
          font-size: 0.875rem;
          line-height: 1.25rem;
          text-decoration: none;
          -ms-transform: translate(-9px);
          transform: translate(-9px);

          :hover {
            border-bottom: 1px solid transparent;
            color: var(--gb-brand-primary);

            span {
              border-bottom: 1px solid transparent;
            }
          }

          :active {
            color: var(--gb-white);
          }

          i {
            position: relative;
            top: unset;
            left: unset;
            margin-right: 1rem;
            transform: none;
          }
        }

        button {
          &:global(.plain) {
            color: var(--gb-brand-primary-light);
            font-weight: 500;
            :hover {
              color: var(--gb-brand-primary);
            }
          }
        }
      }
    }

    &.MetaHidden {
      transform: translateX(-1.25rem);
      opacity: 0;
    }
  }
}
