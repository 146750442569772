@use 'styles/base/mixins';
@use 'styles/base/variables';

.ciamGddsOverlay {
  position: absolute;
  display: none;
  top: 2.5rem;
  background-color: variables.$gb_white;
  padding: 1.5rem;
  min-width: 15rem;
  z-index: 1;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);

  &.opened {
    display: block;

    button {
      margin-bottom: 0;

      :global(.c-tpp-area) {
        align-items: center;
        min-width: fit-content;
      }
    }

    .loggedInButtons {
      button {
        justify-content: left;
      }

      & + button {
        margin-top: 1rem;

        svg {
          fill: variables.$gb_white;
        }
      }
    }
  }

  button {
    width: 100%;
    margin-bottom: 1rem;
  }

  .register {
    display: flex;

    span {
      align-self: center;
    }
  }

  a:hover {
    cursor: pointer;
  }
}

.ciamHeaderWrapper {
  position: relative;

  &:global(.campus) {
    margin-top: -0.125rem;
  }

  & > button {
    color: variables.$gb_black;

    @include mixins.screen-md {
      color: variables.$gb_grey_650;
    }

    &:hover {
      color: var(--gb-brand-primary);
    }

    & > i {
      display: block;

      @include mixins.screen-md {
        margin-top: 0.125rem;
      }

      &::before {
        font-size: 0.9375rem;
      }
    }
  }

  .buttonLoggedIn {
    i::after {
      position: absolute;
      right: -2px;
      bottom: -3px;
      content: '';
      border: 3px solid var(--gb-brand-primary);
      border-radius: 50%;
    }
  }
}

.ciamLoginWrapper {
  position: absolute;
  top: calc(100% + 0.375rem);
  left: calc(50% - var(--offset-right, 0px));
  width: 15rem;
  padding: 1.5rem 1.5rem 1.25rem;
  box-shadow: variables.$box-shadow-dropdown;
  background: variables.$gb_white;
  transform: translateX(-50%);
  z-index: 99;

  &:global(.campus) {
    top: calc(100% + 0.575rem);
  }

  &::before,
  &::after {
    position: absolute;
    left: calc(50% + var(--offset-right, 0px));
    transform: translateX(-50%);
    content: ' ';
    width: 0;
    height: 0;
    border-style: solid;
  }

  &::before {
    border-width: 0 0.625rem 0.625rem 0.625rem;
    border-color: transparent transparent variables.$gb_white transparent;
    top: -0.55rem;
    z-index: 1;
  }

  &::after {
    border-width: 0 0.625rem 0.625rem 0.625rem;
    border-color: transparent transparent variables.$gb_grey_100 transparent;
    top: -0.675rem;
  }

  > :global(.button) {
    padding: 0.625rem 0;

    i::before {
      top: -1px;
    }
  }
}

.ciamLoginNoID {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.125rem;

  span,
  button {
    font-size: 0.75rem;
    line-height: 1;
    text-align: left;
  }

  span {
    color: variables.$gb_grey_650;
  }

  button {
    position: relative;
    padding-left: 0.875rem;
    color: var(--gb-brand-primary-light);

    i {
      position: absolute;
      left: 0;
      margin-right: 0.1875rem;
      margin-top: -0.125rem;

      &::before {
        font-size: 0.625rem;
      }
    }
  }
}

.ciamLoggedInWrapper {
  display: flex;
  flex-direction: column;

  button:last-child {
    margin-top: 1rem;

    div {
      margin: 0 auto;
    }
  }

  .ciamLinkButton {
    padding-top: 0.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 0.9375rem;
    line-height: 1.5rem;
    text-align: left;
    color: var(--gb-brand-primary-light);

    i {
      margin-right: 0.3125rem;
      position: relative;
    }
  }
}
